import React from "react"
import classNames from "classnames"

import styles from "../../utils/cart.module.scss"

const DiscountFreeProduct = ({ discountValue, medicine }) => {
  const discountFreeProduct = discountValue?.freeProduct

  if (discountValue?.freeProduct)
    return (
      <div className="columns is-mobile">
        <div
          className={classNames(
            "column has-text-weight-bold",
            styles["column"]
          )}
        >
          {discountFreeProduct}
        </div>
      </div>
    )
  return null
}

export default DiscountFreeProduct
