import React from "react"
import classNames from "classnames"

import styles from "../../utils/cart.module.scss"

const DiscountDescription = ({ discountValue, discountAmount }) => {
  const discountDescription =
    discountValue?.type === "amount" ? (
      <span className="has-text-weight-bold">
        {discountValue?.code?.startsWith("SCPWD")
          ? "SCPWD Discount"
          : `${
              parseFloat(discountValue?.percentOff) % 1 === 0
                ? parseFloat(discountValue?.percentOff).toFixed(0)
                : parseFloat(discountValue?.percentOff).toFixed(2)
            }% Discount`}
      </span>
    ) : (
      <span>Qty: {discountValue?.quantity}</span>
    )
  const discountValueAmount =
    discountValue?.type === "amount" ? `(P${discountAmount})` : "P0.00"

  if (discountValue?.type)
    return (
      <div
        className={classNames("columns is-mobile", {
          "mt-1": discountValue?.type === "amount",
          "mb-1": discountValue?.type === "quantity",
        })}
      >
        <div className={classNames("column", styles["column"])}>
          {discountDescription}
        </div>
        <div className={classNames("column is-narrow", styles["column"])}>
          <div className="is-size-6 has-text-weight-bold">
            {discountValueAmount}
          </div>
        </div>
      </div>
    )
  return null
}

export default DiscountDescription
