import React, { Fragment } from "react"

import MedicineInfo from "./MedicinesCardRow/MedicineInfo"
import MedicineQtyInfo from "./MedicinesCardRow/MedicineQtyInfo"
import DiscountFreeProduct from "./MedicinesCardRow/DiscountFreeProduct"
import DiscountDescription from "./MedicinesCardRow/DiscountDescription"
import MedicineSubtotalInfo from "./MedicinesCardRow/MedicineSubtotalInfo"

import { formatPrice } from "../../Epharmacy/services/computations"

const MedicinesCardRow = ({ medicine, handleDeleteMedicine, index }) => {
  let discountValues = medicine?.discountValues || []

  return (
    <li>
      <MedicineInfo
        medicine={medicine}
        handleDeleteMedicine={handleDeleteMedicine}
        index={index}
      />
      <MedicineQtyInfo medicine={medicine} />
      {discountValues.map((discountValue) => {
        let discountAmount = formatPrice({
          priceString: discountValue?.amount?.toFixed(2),
        })

        if (discountValue?.quantity || discountValue?.amount)
          return (
            <Fragment>
              <DiscountFreeProduct
                medicine={medicine}
                discountValue={discountValue}
              />
              <DiscountDescription
                discountAmount={discountAmount}
                discountValue={discountValue}
              />
            </Fragment>
          )
        return null
      })}
      <MedicineSubtotalInfo
        medicine={medicine}
        discountValues={discountValues}
      />
    </li>
  )
}

export default MedicinesCardRow
