import React from "react"
import classNames from "classnames"

import styles from "../../utils/cart.module.scss"
import { generateTotalMedsToPay } from "../../../Epharmacy/services/cart"
import {
  formatPrice,
  generatePrice,
} from "../../../Epharmacy/services/computations"

const MedicineQtyInfo = ({ medicine }) => {
  const totalMedsToPay = generateTotalMedsToPay({
    medicine,
    quantity: medicine?.qty,
  })
  const itemPrice = formatPrice({
    priceString: (generatePrice(medicine) * totalMedsToPay).toFixed(2),
  })

  return (
    <div className="columns mb-2 is-mobile">
      <div className={classNames("column", styles["column"])}>
        Qty: <span>{totalMedsToPay}</span>
      </div>
      <div
        className={classNames(
          "column has-text-weight-bold is-size-6 is-narrow",
          styles["column"]
        )}
      >
        P{itemPrice}
      </div>
    </div>
  )
}

export default MedicineQtyInfo
