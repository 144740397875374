import { formatPrice } from "./computations"

export const changeQuantity = (setFieldValue, qty, callback) => {
  if (qty > 0) {
    setFieldValue("qty", qty)
    if (callback) callback()
  }
}

export const isMedicineAlreadyInCart = (medicineToCheck, medicinesList) => {
  const medicineList = [...medicinesList]
  let filteredMedicines = medicineList.filter(
    (medicine) => medicineToCheck.productTitle === medicine.productTitle
  )

  return filteredMedicines.length > 0
}

export const getMedicineForm = ({ medicine, quantity }) => {
  const medicineName = medicine?.productTitle.split(" (")[0] || ""
  let medicineNameArray = medicineName.split(" ")
  let medicineForm = medicineNameArray[
    medicineNameArray.length - 1
  ].toLowerCase()

  return `${medicineForm}${quantity && quantity > 1 ? "s" : ""}`
}

export const generateTotalMedsObtained = ({
  medicine,
  quantity,
  discountValue,
}) => {
  if (discountValue?.freeProduct) {
    let medicineForm = getMedicineForm({ medicine })
    let freebieForm = getMedicineForm({
      medicine: { ...discountValue, productTitle: discountValue?.freeProduct },
    })

    if (medicineForm === freebieForm)
      if (discountValue?.freeProduct === medicine?.productTitle)
        return `${quantity} ${medicineForm}${quantity > 1 ? "s" : ""}`
      else
        return `${quantity + discountValue?.quantity} ${medicineForm}${
          quantity + discountValue?.quantity > 1 ? "s" : ""
        }`
    else
      return `${quantity} ${medicineForm}${quantity > 1 ? "s" : ""} + ${
        discountValue?.quantity
      } ${freebieForm}${discountValue?.quantity > 1 ? "s" : ""}`
  } else
    return `${quantity} ${getMedicineForm({ medicine })}${
      quantity > 1 ? "s" : ""
    }`
}

export const generateTotalMedsToPay = (config) => {
  let { quantity } = config

  return quantity
}

export const generateMedicineAssistanceDescription = (config) => {
  let { isFreeProduct, discountValue } = config

  if (isFreeProduct)
    if (discountValue?.freeProductObject?.drug)
      return `${discountValue?.freeProductObject?.drug?.molecule} (${
        discountValue?.freeProductObject?.brand
      }) #${discountValue?.quantity || 0}`
    else
      return `${discountValue?.freeProduct?.split(" (")[0]} #${
        discountValue?.quantity || 0
      }`
  else
    return `- (P${formatPrice({
      priceString: (discountValue?.amount || 0).toFixed(2),
    })})`
}

// export const isCartEligible = (criteria) => {
//   let { medicines, province, city } = criteria

//   // Check medicine and province eligibility
//   for (let index in medicineConfig) {
//     let config = medicineConfig[index]

//     if (config.isActive) {
//       let cartEligibility = true

//       let includedMedicines = Object.keys(config?.inclusions?.medicines)
//       let includedMedicine = medicines.find((medicine) =>
//         includedMedicines.includes(medicine?.productTitle)
//       )
//       let includedProvinces =
//         config?.inclusions?.medicines?.[includedMedicine?.productTitle]
//           ?.province || []
//       let includedCities =
//         config?.inclusions?.medicines?.[includedMedicine?.productTitle]?.city?.[
//           province
//         ] || []

//       let cartHasIncludedMedicines = medicines.some((medicine) =>
//         includedMedicines.includes(medicine?.productTitle)
//       )

//       if (!cartHasIncludedMedicines) cartEligibility = false
//       if (!includedProvinces.includes(province)) cartEligibility = false
//       if (includedCities?.length > 0 && !includedCities.includes(city))
//         cartEligibility = false

//       if (cartEligibility) return { config: config, isEligible: true }
//     }
//   }

//   return { config: {}, isEligible: false }
// }
