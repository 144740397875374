import React, { Fragment } from "react"
import classNames from "classnames"

import {
  accumulatePrice,
  generateDeliveryFee,
  formatPrice,
} from "../Epharmacy/services/computations"
import styles from "./utils/cart.module.scss"

const OrderSubtotalSection = ({ medicines, couponDiscount }) => {
  const orderSubtotal = formatPrice({
    priceString: (
      accumulatePrice(medicines) +
      generateDeliveryFee() -
      couponDiscount
    ).toFixed(2),
  })

  return (
    <Fragment>
      <hr
        className={classNames("has-background-light", styles["row__divider"])}
      />
      <div className="columns is-size-6 is-mobile">
        <div className="column has-text-right">Subtotal:</div>
        <div className="column is-narrow has-text-weight-bold">
          <span className="mr-1">P{orderSubtotal}</span>
        </div>
      </div>
      <p className="is-size-7 has-text-left px-1 has-text-grey has-text-weight-bold">
        Total amount and assistance will be verified by our pharmacist via SMS.
      </p>
    </Fragment>
  )
}

export default OrderSubtotalSection
