export const formatPrice = (config) => {
  let { priceString } = config
  let priceFloat = parseFloat(priceString)

  return priceFloat.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const generatePrice = (medicine, products) => {
  let tempMedicine = medicine
  if (products?.length > 0) {
    tempMedicine = products?.find(
      (product) => product?.productTitle === medicine?.productTitle
    )
  }
  if (!tempMedicine.price_list) return (0).toFixed(2)
  let vatex = tempMedicine.price_list.vatex_unit_price || 0
  let vat = tempMedicine.price_list.vat || 0
  return parseFloat(vatex + vat).toFixed(2)
}

export const accumulatePrice = (medicines) => {
  let total = 0
  medicines.forEach((medicine) => {
    total +=
      medicine.qty * parseFloat(generatePrice(medicine)) -
      parseFloat(
        medicine?.discountValues?.reduce(
          (totalValue, currentValue) => totalValue + currentValue?.amount || 0,
          0
        ) || 0
      )
  })
  return total
}

export const generateDeliveryFee = (province) => {
  const MM_DELIVERY_FEE = 0

  return MM_DELIVERY_FEE
}

export const checkIfHasRxRequired = (medicines) => {
  if (medicines.length === 0) return true
  return medicines.some((medicine) => medicine.rxRequired === true)
}
