import React from "react"
import classNames from "classnames"

import styles from "../../utils/cart.module.scss"
import { generateTotalMedsToPay } from "../../../Epharmacy/services/cart"
import {
  formatPrice,
  generatePrice,
} from "../../../Epharmacy/services/computations"

const MedicineSubtotalInfo = ({ medicine, discountValues }) => {
  const totalMedsToPay = generateTotalMedsToPay({
    medicine,
    quantity: medicine?.qty,
  })
  const totalPrice = formatPrice({
    priceString: (
      generatePrice(medicine) * totalMedsToPay -
      (discountValues?.reduce(
        (totalValue, currentValue) => totalValue + currentValue?.amount || 0,
        0
      ) || 0)
    ).toFixed(2),
  })

  return (
    <div className="columns mb-1 is-mobile">
      <div
        className={classNames("column has-text-weight-bold", styles["column"])}
      >
        Subtotal:
      </div>
      <div
        className={classNames(
          "column has-text-weight-bold is-size-6 is-narrow",
          styles["column"]
        )}
      >
        P{totalPrice}
      </div>
    </div>
  )
}

export default MedicineSubtotalInfo
