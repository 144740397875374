import React from "react"
import classNames from "classnames"

import RxIndicator from "../../../Elements/RxIndicator"

import styles from "../../utils/cart.module.scss"

const MedicineInfo = ({ medicine, handleDeleteMedicine, index }) => {
  return (
    <div className="columns is-mobile">
      <div className={classNames("column", styles["column"])}>
        <button
          type="button"
          onClick={() => handleDeleteMedicine(index)}
          className={classNames([
            styles["closeButton"],
            "is-pulled-right ml-1",
          ])}
        >
          <a className="delete" />
        </button>
        <div className="is-flex has-text-weight-bold">
          <span className="has-text-primary is-size-6">
            {medicine?.productTitle}{" "}
          </span>
          <RxIndicator />
        </div>
      </div>
    </div>
  )
}

export default MedicineInfo
